import {
  FacebookOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import React from "react";

const FacebookIconLinked: React.FC<{ url: string }> = ({ url }) => (
  <a target="_blank" rel="noreferrer" href={url}>
    <FacebookOutlined />
  </a>
);

const TwitterIconLinked: React.FC<{ url: string }> = ({ url }) => (
  <a target="_blank" rel="noreferrer" href={url}>
    <TwitterOutlined />
  </a>
);

const YoutubeIconLinked: React.FC<{ url: string }> = ({ url }) => (
  <a target="_blank" rel="noreferrer" href={url}>
    <YoutubeOutlined />
  </a>
);
const InstagramIconLinked: React.FC<{ url: string }> = ({ url }) => (
  <a target="_blank" rel="noreferrer" href={url}>
    <InstagramOutlined />
  </a>
);

const SocialMediaIcons: React.FC<{
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  youtubeLink: string;
}> = ({ instagramLink, twitterLink, facebookLink, youtubeLink }) => (
  <>
    {facebookLink && <FacebookIconLinked url={facebookLink} />}
    {twitterLink && <TwitterIconLinked url={twitterLink} />}
    {youtubeLink && <YoutubeIconLinked url={youtubeLink} />}
    {instagramLink && <InstagramIconLinked url={instagramLink} />}
  </>
);

export default SocialMediaIcons;
